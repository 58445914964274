body {
    margin: 0;
}

h3 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-top: 0;
}

p {
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 0.35em;
}

.Resizer.vertical {
  width: 11px;
  border: none;
  opacity: 1;
  background-color: #F6F6F6;
  background-image: url(vetical-split-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.Resizer.horizontal {
  height: 11px;
  border: none;
  opacity: 1;
  background-color: #F6F6F6;
  background-image: url(horizontal-split-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.Resizer:hover {
  transition: all 0s ease;
}

.Resizer.vertical:hover, .Resizer.vertical.resizing, .Resizer.horizontal:hover, .Resizer.horizontal.resizing {
  background-color: #F1F1F1;
  border: none;
}

.glossFull {
    border: 1px solid gray;
    border-radius: 4px;
}

.glossManager {
    border-bottom: 1px solid gray;
}

.glossBody {
    flex-grow: 1;
}
.gloss {
    box-sizing: border-box;
    overflow: visible;
    position: relative;
    flex: 1;
    overflow-y: auto;
    max-width: 100% !important;

    ul {
        box-sizing: border-box;
        height: 100%;

        li {
            list-style: none;

            &.closed {
                list-style: none outside url(../../images/glossary/closed.svg);
            }

            &.opened {
                list-style: none outside url(../../images/glossary/opened.svg);
            }
        }
    }

    ul:first-child {
        position: relative;
        width: max-content;
    }
}

.glossElemInactive {
    color: #aaa;
}

.glossFloatButtons {
    position: absolute;
    top: 0;
    z-index: 1;
    left: -10px;
    width: 24px;
    border-radius: 24px;
    padding: 4px 0;
    background-color: #6157ff;

    div {
        width: 24px;
        height: 24px;
        background-position: center center;
        background-size: initial;
        background-repeat: no-repeat;
    }
}

.imgPoint {
    background-image: url(../../images/glossary/point.svg);
}

.imgComma {
    background-image: url(../../images/glossary/comma.svg);
}

.imgEnter {
    background-image: url(../../images/glossary/enter.svg);
}

.imgForward {
    background-image: url(../../images/glossary/forward.svg);
}

.imgBackward {
    background-image: url(../../images/glossary/backward.svg);
}

.scheduler-container {
  display: flex;
  height: calc(100% - 48px);
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;

  div {
    border-color: inherit;
  }

  .scheduler-layout {
    height: 100%;
    width: 100%;
    overflow: scroll;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.with-controls {
        width: 80%;
    }

    .scheduler-header {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #ffffff;
    }

    .selected-area {
      position: absolute;
      background-color: #c9c9c9;
      z-index: -1;
    }

    .scheduler-body {
        height: calc(100% - 48px);
        position: relative;

      .scheduler-event {
        min-height: 25px;
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        text-align: start;
        outline: 0;
        position: absolute;
        overflow: hidden;
        // padding-right: 8px;
        color: #ffffff;
        width: calc(100% - 140px);
        cursor: pointer;

        .event-body {
          height: calc(100% - 2px);
          width: calc(100% - 2px);
          margin: 1px;
          background-color: #1274AC;
          border-radius: 6px;
        }

        .event-title {
          line-height: 17px;
          padding-block: 4px;
          padding-inline: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.continuing {
          .event-body {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &.prolongation {
          .event-body {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    .scheduler-row {
      display: flex; flex: 1 1 100%; width: 100%; min-width: 0
    }

    .scheduler-cell {
      display: flex;
      flex: 1 1 100%;
      padding-block: 4px;
      padding-inline: 4px;
      min-height: 1.5em;
      overflow: hidden;
      white-space: nowrap;
      border-style: solid;
      border-width: 0 1px 1px 0;
      vertical-align: top;
      box-sizing: content-box;
      min-width: 100px;

      &:not(.side-cell):not(.heading-cell):not(.disabled):hover {
        background-color: rgba(150,150,150,0.21);
        border-bottom: none;
      }

      &.side-cell {
        min-width: 60px;
        flex-basis: auto;
        justify-content: center;
      }

      &.heading-cell {
        justify-content: center;
        font-weight: 400;
      }

      &.disabled {
        background-color: rgba(128, 128, 128, 0.21);
      }
    }
  }

  .scheduler-controls {
    width: 20%;
  }
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto'), url(../public/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 300;
    src: local('Onest'), url(../public/fonts/Onest/Onest-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 400;
    src: local('Onest'), url(../public/fonts/Onest/Onest-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 500;
    src: local('Onest'), url(../public/fonts/Onest/Onest-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Onest';
    font-weight: 700;
    src: local('Onest'), url(../public/fonts/Onest/Onest-Bold.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: 'Onest';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Resizer {
    background: #000;
    opacity: .12;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;

    &:hover {
        transition: all 2s ease;
    }

    &.horizontal {
        height: 11px;
        border-top: 5px solid rgba(255, 255, 255, 0);
        border-bottom: 5px solid rgba(255, 255, 255, 0);
        cursor: row-resize;

        &:hover, &.resizing {
            border-top: 5px solid rgba(0, 0, 0, 0.5);
            border-bottom: 5px solid rgba(0, 0, 0, 0.5);
        }
    }

    &.vertical {
        width: 11px;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
        cursor: col-resize;

        &:hover, &.resizing {
            border-left: 5px solid rgba(0, 0, 0, 0.5);
            border-right: 5px solid rgba(0, 0, 0, 0.5);
        }
    }
}

.DragLayer {
    z-index: 1;
    pointer-events: none;

    &.resizing {
        pointer-events: auto;
    }

    &.horizontal {
        cursor: row-resize;
    }

    &.vertical {
        cursor: col-resize;
    }
}

*, *::before, *::after {
    box-sizing: initial;
}

html {
    --thumbBG: #00000059;
    --thumbHdnBG: rgba(0, 0, 0, 0.2);
}
*::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--thumbHdnBG);
    border-radius: 6px;
    background-clip: padding-box;
    border: 2.5px solid transparent;

    &:hover {
        background-color: var(--thumbBG);
        border: 0;
    }
}

.scrollbar-hidden {
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: var(--thumbHdnBG);

            &:hover {
                background-color: var(--thumbBG);
                border: 0;
            }
        }
    }
}

::-webkit-scrollbar-track {
    background: transparent;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG);
}

.scheduler {
  height: 100%;
  margin: 0;

  thead {
      .ant-row-flex {
          margin-left: 20px;
          margin-right: 20px;
      }
  }

  tbody {
    td {
      vertical-align: top;

      .resource-view, .scheduler-view {
        height: 100%;
        vertical-align: top;
        border-bottom: none;

        div:nth-of-type(2) {
          height: calc(100% - 40px);
        }
      }
    }
  }
}

.scheduler-bg {
  tr {
    border-bottom: none !important;

    td {
      background-color: #fff !important;
      border-right: none;
      border-bottom: none;
    }
  }
}
